

// Primarys Colors
$standardTextColor: #4d4d4d;

$primaryColor: #004a6c;
$secondaryColor: #009997;
$buttonDisableColor: #9c9c9c;
$buttonDisableBgColor: #7676764d;

// Menu Colors

$bgMenuColor: #1d1d1d;
$linkMenuColor: #7b8089;
$iconMenuColor: #2a2a2a;
$borderMenuColor: #323232;

// Botão Colors

$btnPrimary: #00b8d4;
$btnPrimaryHover: #0085b0;

// Generics Colors

$whiteColor: #ffffff;
$mutedColor: #8f8f8f;
$cardColor: #f3f4f7;
$cardColorLight: #f9fafc;
$cardColorLightTwo: #f1f5fc;
$greyLight: #d1d1d1;

// Dropdown Colors

$dropdownHeaderColor: #5f5f5f;
$dropdownColor: #ebffff;

// State Colors

$successColor: #3dc661;
$dangerColor: #e7515a;
$warningColor: #e2a03f;
$infoColor: #2196f3;
$defaultColor: #172b4d;
$lightColor: #e0e0e0;