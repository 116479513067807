@import "materialdesignicons.scss";
@import "_variables.scss";

.sf-menu {
    transition: all  0.15s ease;
    background-color: $bgMenuColor;
    overflow-x: hidden;
    overflow-y: auto;
    position: fixed;
    height: 100vh;
    width: 300px;
    left: 0;
    top: 0;
    -webkit-box-shadow: 1px 0.05rem 0.01rem rgb(75 75 90 / 8%);
    box-shadow: 1px 0.05rem 0.01rem rgb(75 75 90 / 8%);
    z-index: 9;
    .sf-menu-header {
        height: 92px;
        display: flex;
        align-items: center;
        border-bottom: 1px solid $borderMenuColor;
        .sf-logo-link {
            opacity: 1;
            padding: 1.25rem 2.25rem;
            transition: opacity 0.5s linear;
            .logo {
                max-width: 100%;
                max-height: 3rem;
            }
        }
        .hamburger-link {
            padding: 1.5rem;
            margin-left: auto;
            cursor: pointer;
        }
    }
    .sf-menu-body {
        padding: 1.25rem 0;
        height: calc(100vh - 110px);
        visibility: hidden;
        .sf-menu-ul {
            visibility: visible;
            .sf-menu-li {
                .sf-menu-list-link {
                    color: $linkMenuColor;
                    display: flex;
                    align-items: center;
                    text-decoration: none;
                    padding: 0.75rem 2.25rem;
                    white-space: nowrap;
                    transition: color 180ms ease-in 0s, background-color 180ms ease-in 0s;
                    .menu-list-icon {
                        background-color: $iconMenuColor;
                        padding: 0.55rem;
                        border-radius: 4px;
                        min-width: 38px;
                        min-height: 20px;
                        height: 38px;
                        margin-right: 1.125rem;
                        font-size: 1.25rem;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                    .menu-list-icon-arrow {
                        transition: transform 0.15s linear;
                        font-size: 1.25rem;
                        margin-left: auto;
                    }
                    &:hover {
                        color: $whiteColor;
                    }
                    &.active {
                        color: $whiteColor;
                        background-color: $cardColor;
                        .menu-list-icon-arrow {
                            transform: rotate(90deg);
                        }
                    }
                    &.active-nolink {
                        color: $btnPrimary;
                        font-weight: bold;
                    }
                }
                position: relative;
                .sf-menu-li-float {
                    transition: padding-left 0.07s linear;
                    transform: translateY(-50%);
                    position: absolute;
                    visibility: hidden;
                    padding-left: 0;
                    left: 100%;
                    top: 50%;
                    .sf-menu-li-float-content {
                        background-color: $iconMenuColor;
                        border-radius: 4px;
                        .sf-menu-li-float-span {
                            padding: 0.438rem 1rem;
                            text-decoration: none;
                            align-items: center;
                            white-space: nowrap;
                            border-radius: 4px;
                            color: $whiteColor;
                            cursor: pointer;
                            display: flex;
                            &:hover {
                                background-color: darken($iconMenuColor, 4%);
                            }
                        }
                    }
                    &.active-nolink {
                        .sf-menu-li-float-content {
                            .sf-menu-li-float-span {
                                color: $btnPrimary;
                                font-weight: bold;
                            }
                        }
                    }
                }
            }
        }
        .menu-list-content {
            transition: 0.15s height linear;
            overflow: hidden;
            height: 0;
            .menu-list-level-two {
                background-color: $cardColorLight;
                padding-left: 55px;
                .menu-list-level-two-item {
                    padding-top: 0.45rem;
                    padding-bottom: 0.45rem;
                    .menu-list-level-two-link {
                        color: #5f5f5f;
                        display: flex;
                        align-items: center;
                        text-decoration: none;
                        padding: 0.5rem 2.25rem 0.5rem 1.25rem;
                        font-size: 0.875rem;
                    }
                }
            }
        }
    }
}

.footer {
    bottom: 0;
    padding: 1rem 2rem;
    position: absolute;
    right: 0;
    left: 300px;
}

// Barra Topo
.bar-header {
    background-color: $iconMenuColor;
    display: flex;
    align-items: center;
    margin-left: 300px;
    padding: 0 2rem;
    -webkit-box-shadow: 0 0.05rem 0.01rem rgba(75, 75, 90, 0.075);
    box-shadow: 0 0.05rem 0.01rem rgba(75, 75, 90, 0.075);
    transition: all 0.15s ease;
}
.bar-header .ola-usuario {
    margin: 0;
}
.bar-header .usuario {
    font-weight: 700;
    color: $linkMenuColor;
}
.bar-header .bloco-usuario {
    display: flex;
    margin-left: auto;
}
.bar-header .bloco-usuario .lista-util-usuario {
    display: flex;
    align-items: center;
    margin-right: 0.5rem;
}
.bar-header .bloco-usuario .lista-util-usuario .lista-util-usuario-item {
    display: inline-block;
}
.bar-header .bloco-usuario .lista-util-usuario .lista-util-usuario-item .lista-util-usuario-link {
    display: block;
    cursor: pointer;
    padding: 0.625rem 1rem;
    font-size: 1.25rem;
    color: #5f5f5f;
}
.bar-header
    .bloco-usuario
    .lista-util-usuario
    .lista-util-usuario-item
    .lista-util-usuario-link
    .lista-util-usuario-img {
    fill: #8f8f8f;
}
.bar-header .bloco-usuario .lista-util-usuario .lista-util-usuario-item .lista-util-usuario-link:hover {
    opacity: 0.5;
}
.bar-header .bloco-usuario .bloco-menu-usuario {
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;
}
.bar-header .bloco-usuario .bloco-menu-usuario .bloco-imagem-usuario {
    height: 100%;
}
.bar-header .bloco-usuario .bloco-menu-usuario .bloco-imagem-usuario .imagem-usuario {
    height: 36px;
    width: 36px;
    padding: 3px;
    background-color: #f3f4f7;
    border-radius: 50%;
}
// Barra Topo

.ga-mascara-fundo {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    z-index: 8;
    background-color: #000000;
    opacity: 0.5;
    transition: all .15s ease;
}

@media screen and (min-width: 768px) {
    body.menu-fechado {
        .sf-menu {
            overflow: initial;
            width: 62px;
            .sf-menu-header {
                .sf-logo-link {
                    display: none;
                }
                .hamburger-link {
                    padding: 1.5em 1.1875em;
                    margin: 0;
                }
            }
            .sf-menu-body {
                // overflow: hidden;
                .sf-menu-ul {
                    .sf-menu-li {
                        height: 62px;
                        .sf-menu-list-text,
                        .menu-list-icon-arrow {
                            display: none;
                        }
                        .sf-menu-list-link {
                            padding-left: 0;
                            padding-right: 0;
                            .menu-list-icon {
                                margin: 0 12px;
                            }
                        }
                        &:hover {
                            .sf-menu-li-float {
                                visibility: visible;
                                padding-left: 20px;
                            }
                        }
                    }
                }
            }
            // &:hover {
            //     max-width: 300px;
            //     .sf-menu-header {
            //         .sf-logo-link {
            //             padding: 1.25rem 2.25rem;
            //             display: block;
            //         }
            //         .hamburger-link {
            //             padding: 1.5em;
            //             margin-left: auto;
            //         }
            //     }
            //     .sf-menu-body {
            //         overflow-y: auto;
            //         .sf-menu-ul {
            //             .sf-menu-li {
            //                 height: auto;
            //                 .sf-menu-list-text,
            //                 .menu-list-icon-arrow {
            //                     display: block;
            //                 }
            //                 .sf-menu-list-link {
            //                     padding: 0.75rem 2.25rem;
            //                     .menu-list-icon {
            //                         margin: 0 1.125rem 0 0;
            //                     }
            //                 }
            //             }
            //         }
            //     }
            // }
        }
        .bar-header,
        .sf-content-page {
            margin-left: 62px;
        }
        .footer {
            left: 62px;
        }
    }
}

@media screen and (max-width: 767px) {
    body.menu-mobile-aberto {
        overflow: hidden;
    } 
    body.menu-mobile-aberto .sf-menu {
        transform: translateX(0px);
    }
    body.menu-mobile-aberto .ga-mascara-fundo {
        display: block;
    }

    .sf-menu {
        transform: translateX(-250px);
        width: 250px;
        max-width: 250px;
        box-shadow: none;
    }
    .sf-menu .sf-menu-header .logo-link {
        padding: 1.5rem;
    }
    .sf-menu .sf-menu-body {
        visibility: visible;
    }
    .sf-menu .sf-menu-body .sf-menu-lista .sf-menu-lista-item .sf-menu-lista-link {
        padding: 0.75rem 1.5rem;
    }
    .sf-menu .sf-menu-body .sf-menu-lista-nivel-2 {
        padding-left: 44px;
    }
    .sf-menu .sf-menu-body .sf-menu-lista-nivel-2 .sf-menu-lista-nivel-2-item .sf-menu-lista-nivel-2-link {
        padding: 0.5rem 1.5rem 0.5rem 1.25rem;
    }

    .bar-header {
        position: fixed;
        width: 100%;
        top: 0;
        z-index: 8;
        padding: 0 1rem;
        margin: 0;
    }

    .sf-content-page {
        margin: 0;
    }

    .footer {
        left: 0;
        padding: 0.5rem 1rem;
    }
}
@media screen and (max-width: 576px) {
    .bar-header .bloco-usuario .ga-dropdown {
        position: static;
    }
    .bar-header .bloco-usuario .ga-dropdown .ga-dropdown-menu {
        width: calc(100% - 2rem);
        min-width: auto;
        left: 1rem;
        top: 100%;
        transform: translateY(0px);
    }
}