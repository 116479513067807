@import "./variables";
@font-face {
  font-family: "Nunito Sans";
  src: url("../fonts/NunitoSans/NunitoSans-Regular.ttf") format("truetype");
  font-weight: 400;
}
@font-face {
  font-family: "Nunito Sans";
  src: url("../fonts/NunitoSans/NunitoSans-Light.ttf") format("truetype");
  font-weight: 300;
}
@font-face {
  font-family: "Nunito Sans";
  src: url("../fonts/NunitoSans/NunitoSans-SemiBold.ttf") format("truetype");
  font-weight: 600;
}
@font-face {
  font-family: "Nunito Sans";
  src: url("../fonts/NunitoSans/NunitoSans-Bold.ttf") format("truetype");
  font-weight: 700;
}
@font-face {
  font-family: "Nunito Sans";
  src: url("../fonts/NunitoSans/NunitoSans-ExtraBold.ttf") format("truetype");
  font-weight: 800;
}

/* MIXINS */
/* UTILITIES */
*,
*::before,
*::after {
  box-sizing: border-box;
}
* {
  outline: none;
}

html {
  min-height: 100%;
  position: relative;
}

body {
  margin: 0;
  font-family: "Nunito Sans", sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: $standardTextColor;
  text-align: left;
}

[tabindex="-1"]:focus {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

ul {
  padding: 0;
  list-style: none;
  margin: 0;
}

a {
  color: $secondaryColor;
  text-decoration: none;
  background-color: transparent;
}
a:hover {
  text-decoration: underline;
  color: $secondaryColor;
}

.sf-standard-width {
  max-width: 1480px;
  margin: 0 auto;
  // width: 90%;
}

.sf-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  box-sizing: border-box;
  margin-right: -12px;
  margin-left: -12px;
}

.sf-col-xl,
.sf-col-xl-1,
.sf-col-xl-2,
.sf-col-xl-3,
.sf-col-xl-4,
.sf-col-xl-5,
.sf-col-xl-6,
.sf-col-xl-7,
.sf-col-xl-8,
.sf-col-xl-9,
.sf-col-xl-10,
.sf-col-xl-11,
.sf-col-xl-12,
.sf-col-xl-auto,
.sf-col-lg,
.sf-col-lg-1,
.sf-col-lg-2,
.sf-col-lg-3,
.sf-col-lg-4,
.sf-col-lg-5,
.sf-col-lg-6,
.sf-col-lg-7,
.sf-col-lg-8,
.sf-col-lg-9,
.sf-col-lg-10,
.sf-col-lg-11,
.sf-col-lg-12,
.sf-col-lg-auto,
.sf-col-md,
.sf-col-md-1,
.sf-col-md-2,
.sf-col-md-3,
.sf-col-md-4,
.sf-col-md-5,
.sf-col-md-6,
.sf-col-md-7,
.sf-col-md-8,
.sf-col-md-9,
.sf-col-md-10,
.sf-col-md-11,
.sf-col-md-12,
.sf-col-md-auto,
.sf-col-sm,
.sf-col-sm-1,
.sf-col-sm-2,
.sf-col-sm-3,
.sf-col-sm-4,
.sf-col-sm-5,
.sf-col-sm-6,
.sf-col-sm-7,
.sf-col-sm-8,
.sf-col-sm-9,
.sf-col-sm-10,
.sf-col-sm-11,
.sf-col-sm-12,
.sf-col-sm-auto,
.sf-col,
.sf-col-1,
.sf-col-2,
.sf-col-3,
.sf-col-4,
.sf-col-5,
.sf-col-6,
.sf-col-7,
.sf-col-8,
.sf-col-9,
.sf-col-10,
.sf-col-11,
.sf-col-12,
.sf-col-auto {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 12px;
  padding-left: 12px;
}

.sf-col {
  flex-basis: 0;
  flex-grow: 1;
  -webkit-box-flex: 1;
  max-width: 100%;
  box-sizing: border-box;
}

.sf-col-1 {
  -ms-flex: 0 0 8.333333%;
  flex: 0 0 8.333333%;
  max-width: 8.333333%;
}

.sf-col-2 {
  -ms-flex: 0 0 16.666667%;
  flex: 0 0 16.666667%;
  max-width: 16.666667%;
}

.sf-col-3 {
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%;
}

.sf-col-4 {
  -ms-flex: 0 0 33.333333%;
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}

.sf-col-5 {
  -ms-flex: 0 0 41.666667%;
  flex: 0 0 41.666667%;
  max-width: 41.666667%;
}

.sf-col-6 {
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
}

.sf-col-7 {
  -ms-flex: 0 0 58.333333%;
  flex: 0 0 58.333333%;
  max-width: 58.333333%;
}

.sf-col-8 {
  -ms-flex: 0 0 66.666667%;
  flex: 0 0 66.666667%;
  max-width: 66.666667%;
}

.sf-col-9 {
  -ms-flex: 0 0 75%;
  flex: 0 0 75%;
  max-width: 75%;
}

.sf-col-10 {
  -ms-flex: 0 0 83.333333%;
  flex: 0 0 83.333333%;
  max-width: 83.333333%;
}

.sf-col-11 {
  -ms-flex: 0 0 91.666667%;
  flex: 0 0 91.666667%;
  max-width: 91.666667%;
}

.sf-col-12 {
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
}

.sf-col-auto {
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

@media screen and (min-width: 576px) {
  .sf-col-sm {
    flex-basis: 0;
    flex-grow: 1;
    -webkit-box-flex: 1;
    max-width: 100%;
    box-sizing: border-box;
  }

  .sf-col-sm-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }

  .sf-col-sm-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }

  .sf-col-sm-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .sf-col-sm-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }

  .sf-col-sm-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }

  .sf-col-sm-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .sf-col-sm-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }

  .sf-col-sm-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }

  .sf-col-sm-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }

  .sf-col-sm-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }

  .sf-col-sm-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }

  .sf-col-sm-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .sf-col-sm-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
}
@media screen and (min-width: 768px) {
  .sf-col-md {
    flex-basis: 0;
    flex-grow: 1;
    -webkit-box-flex: 1;
    max-width: 100%;
    box-sizing: border-box;
  }

  .sf-col-md-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }

  .sf-col-md-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }

  .sf-col-md-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .sf-col-md-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }

  .sf-col-md-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }

  .sf-col-md-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .sf-col-md-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }

  .sf-col-md-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }

  .sf-col-md-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }

  .sf-col-md-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }

  .sf-col-md-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }

  .sf-col-md-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .sf-col-md-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
}
@media screen and (min-width: 992px) {
  .sf-col-lg {
    flex-basis: 0;
    flex-grow: 1;
    -webkit-box-flex: 1;
    max-width: 100%;
    box-sizing: border-box;
  }

  .sf-col-lg-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }

  .sf-col-lg-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }

  .sf-col-lg-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .sf-col-lg-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }

  .sf-col-lg-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }

  .sf-col-lg-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .sf-col-lg-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }

  .sf-col-lg-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }

  .sf-col-lg-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }

  .sf-col-lg-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }

  .sf-col-lg-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }

  .sf-col-lg-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .sf-col-lg-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
}
@media screen and (min-width: 1200px) {
  .sf-col-xl {
    flex-basis: 0;
    flex-grow: 1;
    -webkit-box-flex: 1;
    max-width: 100%;
    box-sizing: border-box;
  }

  .sf-col-xl-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }

  .sf-col-xl-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }

  .sf-col-xl-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .sf-col-xl-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }

  .sf-col-xl-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }

  .sf-col-xl-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .sf-col-xl-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }

  .sf-col-xl-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }

  .sf-col-xl-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }

  .sf-col-xl-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }

  .sf-col-xl-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }

  .sf-col-xl-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .sf-col-xl-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
}
.sf-display-none {
  display: none !important;
}

.sf-display-inline {
  display: inline !important;
}

.sf-display-inline-block {
  display: inline-block !important;
}

.sf-display-block {
  display: block !important;
}

.sf-display-table {
  display: table !important;
}

.sf-display-table-row {
  display: table-row !important;
}

.sf-display-table-cell {
  display: table-cell !important;
}

.sf-display-flex {
  display: flex !important;
}

.sf-display-inline-flex {
  display: inline-flex !important;
}

@media screen and (min-width: 576px) {
  .sf-display-sm-none {
    display: none !important;
  }

  .sf-display-sm-inline {
    display: inline !important;
  }

  .sf-display-sm-inline-block {
    display: inline-block !important;
  }

  .sf-display-sm-block {
    display: block !important;
  }

  .sf-display-sm-table {
    display: table !important;
  }

  .sf-display-sm-table-row {
    display: table-row !important;
  }

  .sf-display-sm-table-cell {
    display: table-cell !important;
  }

  .sf-display-sm-flex {
    display: flex !important;
  }

  .sf-display-sm-inline-flex {
    display: inline-flex !important;
  }
}
@media screen and (min-width: 768px) {
  .sf-display-md-none {
    display: none !important;
  }

  .sf-display-md-inline {
    display: inline !important;
  }

  .sf-display-md-inline-block {
    display: inline-block !important;
  }

  .sf-display-md-block {
    display: block !important;
  }

  .sf-display-md-table {
    display: table !important;
  }

  .sf-display-md-table-row {
    display: table-row !important;
  }

  .sf-display-md-table-cell {
    display: table-cell !important;
  }

  .sf-display-md-flex {
    display: flex !important;
  }

  .sf-display-md-inline-flex {
    display: inline-flex !important;
  }
}
@media screen and (min-width: 992px) {
  .sf-display-lg-none {
    display: none !important;
  }

  .sf-display-lg-inline {
    display: inline !important;
  }

  .sf-display-lg-inline-block {
    display: inline-block !important;
  }

  .sf-display-lg-block {
    display: block !important;
  }

  .sf-display-lg-table {
    display: table !important;
  }

  .sf-display-lg-table-row {
    display: table-row !important;
  }

  .sf-display-lg-table-cell {
    display: table-cell !important;
  }

  .sf-display-lg-flex {
    display: flex !important;
  }

  .sf-display-lg-inline-flex {
    display: inline-flex !important;
  }
}
@media screen and (min-width: 1200px) {
  .sf-display-xl-none {
    display: none !important;
  }

  .sf-display-xl-inline {
    display: inline !important;
  }

  .sf-display-xl-inline-block {
    display: inline-block !important;
  }

  .sf-display-xl-block {
    display: block !important;
  }

  .sf-display-xl-table {
    display: table !important;
  }

  .sf-display-xl-table-row {
    display: table-row !important;
  }

  .sf-display-xl-table-cell {
    display: table-cell !important;
  }

  .sf-display-xl-flex {
    display: flex !important;
  }

  .sf-display-xl-inline-flex {
    display: inline-flex !important;
  }
}
.sf-flex-row {
  flex-direction: row !important;
}

.sf-flex-column {
  flex-direction: column !important;
}

.sf-flex-row-reverse {
  flex-direction: row-reverse !important;
}

.sf-flex-column-reverse {
  flex-direction: column-reverse !important;
}

.sf-flex-wrap {
  flex-wrap: wrap !important;
}

.sf-flex-nowrap {
  flex-wrap: nowrap !important;
}

.sf-flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.sf-justify-content-start {
  justify-content: flex-start !important;
}

.sf-justify-content-end {
  justify-content: flex-end !important;
}

.sf-justify-content-center {
  justify-content: center !important;
}

.sf-justify-content-between {
  justify-content: space-between !important;
}

.sf-justify-content-around {
  justify-content: space-around !important;
}

.sf-align-items-start {
  align-items: flex-start !important;
}

.sf-align-items-end {
  align-items: flex-end !important;
}

.sf-align-items-center {
  align-items: center !important;
}

.sf-align-items-baseline {
  align-items: baseline !important;
}

.sf-align-items-stretch {
  align-items: stretch !important;
}

.sf-align-content-start {
  align-content: flex-start !important;
}

.sf-align-content-end {
  align-content: flex-end !important;
}

.sf-align-content-center {
  align-content: center !important;
}

.sf-align-content-between {
  align-content: space-between !important;
}

.sf-align-content-around {
  align-content: space-around !important;
}

.sf-align-content-stretch {
  align-content: stretch !important;
}

.sf-align-self-auto {
  align-self: auto !important;
}

.sf-align-self-start {
  align-self: flex-start !important;
}

.sf-align-self-end {
  align-self: flex-end !important;
}

.sf-align-self-center {
  align-self: center !important;
}

.sf-align-self-baseline {
  align-self: baseline !important;
}

.sf-align-self-stretch {
  align-self: stretch !important;
}

@media screen and (min-width: 576px) {
  .sf-flex-sm-row {
    flex-direction: row !important;
  }

  .sf-flex-sm-column {
    flex-direction: column !important;
  }

  .sf-flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }

  .sf-flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }

  .sf-flex-sm-wrap {
    flex-wrap: wrap !important;
  }

  .sf-flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }

  .sf-flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .sf-justify-content-sm-start {
    justify-content: flex-start !important;
  }

  .sf-justify-content-sm-end {
    justify-content: flex-end !important;
  }

  .sf-justify-content-sm-center {
    justify-content: center !important;
  }

  .sf-justify-content-sm-between {
    justify-content: space-between !important;
  }

  .sf-justify-content-sm-around {
    justify-content: space-around !important;
  }

  .sf-align-items-sm-start {
    align-items: flex-start !important;
  }

  .sf-align-items-sm-end {
    align-items: flex-end !important;
  }

  .sf-align-items-sm-center {
    align-items: center !important;
  }

  .sf-align-items-sm-baseline {
    align-items: baseline !important;
  }

  .sf-align-items-sm-stretch {
    align-items: stretch !important;
  }

  .sf-align-content-sm-start {
    align-content: flex-start !important;
  }

  .sf-align-content-sm-end {
    align-content: flex-end !important;
  }

  .sf-align-content-sm-center {
    align-content: center !important;
  }

  .sf-align-content-sm-between {
    align-content: space-between !important;
  }

  .sf-align-content-sm-around {
    align-content: space-around !important;
  }

  .sf-align-content-sm-stretch {
    align-content: stretch !important;
  }

  .sf-align-self-sm-auto {
    align-self: auto !important;
  }

  .sf-align-self-sm-start {
    align-self: flex-start !important;
  }

  .sf-align-self-sm-end {
    align-self: flex-end !important;
  }

  .sf-align-self-sm-center {
    align-self: center !important;
  }

  .sf-align-self-sm-baseline {
    align-self: baseline !important;
  }

  .sf-align-self-sm-stretch {
    align-self: stretch !important;
  }
}
@media screen and (min-width: 768px) {
  .sf-flex-md-row {
    flex-direction: row !important;
  }

  .sf-flex-md-column {
    flex-direction: column !important;
  }

  .sf-flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }

  .sf-flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }

  .sf-flex-md-wrap {
    flex-wrap: wrap !important;
  }

  .sf-flex-md-nowrap {
    flex-wrap: nowrap !important;
  }

  .sf-flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .sf-justify-content-md-start {
    justify-content: flex-start !important;
  }

  .sf-justify-content-md-end {
    justify-content: flex-end !important;
  }

  .sf-justify-content-md-center {
    justify-content: center !important;
  }

  .sf-justify-content-md-between {
    justify-content: space-between !important;
  }

  .sf-justify-content-md-around {
    justify-content: space-around !important;
  }

  .sf-align-items-md-start {
    align-items: flex-start !important;
  }

  .sf-align-items-md-end {
    align-items: flex-end !important;
  }

  .sf-align-items-md-center {
    align-items: center !important;
  }

  .sf-align-items-md-baseline {
    align-items: baseline !important;
  }

  .sf-align-items-md-stretch {
    align-items: stretch !important;
  }

  .sf-align-content-md-start {
    align-content: flex-start !important;
  }

  .sf-align-content-md-end {
    align-content: flex-end !important;
  }

  .sf-align-content-md-center {
    align-content: center !important;
  }

  .sf-align-content-md-between {
    align-content: space-between !important;
  }

  .sf-align-content-md-around {
    align-content: space-around !important;
  }

  .sf-align-content-md-stretch {
    align-content: stretch !important;
  }

  .sf-align-self-md-auto {
    align-self: auto !important;
  }

  .sf-align-self-md-start {
    align-self: flex-start !important;
  }

  .sf-align-self-md-end {
    align-self: flex-end !important;
  }

  .sf-align-self-md-center {
    align-self: center !important;
  }

  .sf-align-self-md-baseline {
    align-self: baseline !important;
  }

  .sf-align-self-md-stretch {
    align-self: stretch !important;
  }
}
@media screen and (min-width: 992px) {
  .sf-flex-lg-row {
    flex-direction: row !important;
  }

  .sf-flex-lg-column {
    flex-direction: column !important;
  }

  .sf-flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }

  .sf-flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }

  .sf-flex-lg-wrap {
    flex-wrap: wrap !important;
  }

  .sf-flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }

  .sf-flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .sf-justify-content-lg-start {
    justify-content: flex-start !important;
  }

  .sf-justify-content-lg-end {
    justify-content: flex-end !important;
  }

  .sf-justify-content-lg-center {
    justify-content: center !important;
  }

  .sf-justify-content-lg-between {
    justify-content: space-between !important;
  }

  .sf-justify-content-lg-around {
    justify-content: space-around !important;
  }

  .sf-align-items-lg-start {
    align-items: flex-start !important;
  }

  .sf-align-items-lg-end {
    align-items: flex-end !important;
  }

  .sf-align-items-lg-center {
    align-items: center !important;
  }

  .sf-align-items-lg-baseline {
    align-items: baseline !important;
  }

  .sf-align-items-lg-stretch {
    align-items: stretch !important;
  }

  .sf-align-content-lg-start {
    align-content: flex-start !important;
  }

  .sf-align-content-lg-end {
    align-content: flex-end !important;
  }

  .sf-align-content-lg-center {
    align-content: center !important;
  }

  .sf-align-content-lg-between {
    align-content: space-between !important;
  }

  .sf-align-content-lg-around {
    align-content: space-around !important;
  }

  .sf-align-content-lg-stretch {
    align-content: stretch !important;
  }

  .sf-align-self-lg-auto {
    align-self: auto !important;
  }

  .sf-align-self-lg-start {
    align-self: flex-start !important;
  }

  .sf-align-self-lg-end {
    align-self: flex-end !important;
  }

  .sf-align-self-lg-center {
    align-self: center !important;
  }

  .sf-align-self-lg-baseline {
    align-self: baseline !important;
  }

  .sf-align-self-lg-stretch {
    align-self: stretch !important;
  }
}
@media screen and (min-width: 1200px) {
  .sf-flex-xl-row {
    flex-direction: row !important;
  }

  .sf-flex-xl-column {
    flex-direction: column !important;
  }

  .sf-flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .sf-flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .sf-flex-xl-wrap {
    flex-wrap: wrap !important;
  }

  .sf-flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }

  .sf-flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .sf-justify-content-xl-start {
    justify-content: flex-start !important;
  }

  .sf-justify-content-xl-end {
    justify-content: flex-end !important;
  }

  .sf-justify-content-xl-center {
    justify-content: center !important;
  }

  .sf-justify-content-xl-between {
    justify-content: space-between !important;
  }

  .sf-justify-content-xl-around {
    justify-content: space-around !important;
  }

  .sf-align-items-xl-start {
    align-items: flex-start !important;
  }

  .sf-align-items-xl-end {
    align-items: flex-end !important;
  }

  .sf-align-items-xl-center {
    align-items: center !important;
  }

  .sf-align-items-xl-baseline {
    align-items: baseline !important;
  }

  .sf-align-items-xl-stretch {
    align-items: stretch !important;
  }

  .sf-align-content-xl-start {
    align-content: flex-start !important;
  }

  .sf-align-content-xl-end {
    align-content: flex-end !important;
  }

  .sf-align-content-xl-center {
    align-content: center !important;
  }

  .sf-align-content-xl-between {
    align-content: space-between !important;
  }

  .sf-align-content-xl-around {
    align-content: space-around !important;
  }

  .sf-align-content-xl-stretch {
    align-content: stretch !important;
  }

  .sf-align-self-xl-auto {
    align-self: auto !important;
  }

  .sf-align-self-xl-start {
    align-self: flex-start !important;
  }

  .sf-align-self-xl-end {
    align-self: flex-end !important;
  }

  .sf-align-self-xl-center {
    align-self: center !important;
  }

  .sf-align-self-xl-baseline {
    align-self: baseline !important;
  }

  .sf-align-self-xl-stretch {
    align-self: stretch !important;
  }
}
.sf-text-justify {
  text-align: justify !important;
}

.sf-text-wrap {
  white-space: wrap !important;
}

.sf-text-nowrap {
  white-space: nowrap !important;
}

.sf-text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.sf-text-left {
  text-align: left !important;
}

.sf-text-right {
  text-align: right !important;
}

.sf-text-center {
  text-align: center !important;
}

@media screen and (min-width: 576px) {
  .sf-text-sm-left {
    text-align: left !important;
  }

  .sf-text-sm-right {
    text-align: right !important;
  }

  .sf-text-sm-center {
    text-align: center !important;
  }
}
@media screen and (min-width: 768px) {
  .sf-text-md-left {
    text-align: left !important;
  }

  .sf-text-md-right {
    text-align: right !important;
  }

  .sf-text-md-center {
    text-align: center !important;
  }
}
@media screen and (min-width: 992px) {
  .sf-text-lg-left {
    text-align: left !important;
  }

  .sf-text-lg-right {
    text-align: right !important;
  }

  .sf-text-lg-center {
    text-align: center !important;
  }
}
@media screen and (min-width: 1200px) {
  .sf-text-xl-left {
    text-align: left !important;
  }

  .sf-text-xl-right {
    text-align: right !important;
  }

  .sf-text-xl-center {
    text-align: center !important;
  }
}
.sf-text-lowercase {
  text-transform: lowercase !important;
}

.sf-text-uppercase {
  text-transform: uppercase !important;
}

.sf-text-capitalize {
  text-transform: capitalize !important;
}

.sf-cursor-pointer {
  cursor: pointer;
}

.sf-text-xxs {
  font-size: 0.625rem !important;
}

.sf-text-xs {
  font-size: 0.75rem !important;
}

.sf-text-sm {
  font-size: 0.875rem !important;
}

.sf-text-md {
  font-size: 1rem !important;
}

.sf-text-lg {
  font-size: 1.25rem !important;
}

.sf-text-xl {
  font-size: 1.5rem !important;
}

.sf-text-xlg {
  font-size: 2rem !important;
}

.sf-font-weight-light {
  font-weight: 300 !important;
}

.sf-font-weight-normal {
  font-weight: 400 !important;
}

.sf-font-weight-semi-bold {
  font-weight: 500 !important;
}

.sf-font-weight-bold {
  font-weight: 600 !important;
}

.sf-font-weight-extra-bold {
  font-weight: 700 !important;
}

.sf-font-italic {
  font-style: italic !important;
}

.sf-text-white {
  color: $whiteColor !important;
}

.sf-text-reset {
  color: inherit !important;
}

.sf-text-muted {
  color: $mutedColor !important;
}

.sf-text-primary {
  color: $primaryColor !important;
}

.sf-text-secondary {
  color: $secondaryColor !important;
}

.sf-text-success {
  color: $successColor !important;
}

.sf-text-danger {
  color: $dangerColor !important;
}

.sf-text-warning {
  color: $warningColor !important;
}

.sf-text-info {
  color: $infoColor !important;
}

.sf-text-info-hover {
  &:hover {
    color: $infoColor !important;
  }
}

.sf-text-info-underline-hover {
  &:hover {
    color: $infoColor !important;
    text-decoration: underline !important;
  }
}

.sf-text-default {
  color: $defaultColor !important;
}

.sf-text-light {
  color: $lightColor !important;
}

.sf-text-decoration-none {
  text-decoration: none !important;
}

.sf-ml-12 {
  margin-left: 12px !important;
}

.sf-m-0 {
  margin: 0 !important;
}

.sf-mt-0,
.sf-my-0 {
  margin-top: 0 !important;
}

.sf-mr-0,
.sf-mx-0 {
  margin-right: 0 !important;
}

.sf-mb-0,
.sf-my-0 {
  margin-bottom: 0 !important;
}

.sf-ml-0,
.sf-mx-0 {
  margin-left: 0 !important;
}

.sf-m-1 {
  margin: 0.25rem !important;
}

.sf-mt-1,
.sf-my-1 {
  margin-top: 0.25rem !important;
}

.sf-mr-1,
.sf-mx-1 {
  margin-right: 0.25rem !important;
}

.sf-mb-1,
.sf-my-1 {
  margin-bottom: 0.25rem !important;
}

.sf-ml-1,
.sf-mx-1 {
  margin-left: 0.25rem !important;
}

.sf-m-2 {
  margin: 0.5rem !important;
}

.sf-mt-2,
.sf-my-2 {
  margin-top: 0.5rem !important;
}

.sf-mr-2,
.sf-mx-2 {
  margin-right: 0.5rem !important;
}

.sf-mb-2,
.sf-my-2 {
  margin-bottom: 0.5rem !important;
}

.sf-ml-2,
.sf-mx-2 {
  margin-left: 0.5rem !important;
}

.sf-m-3 {
  margin: 1rem !important;
}

.sf-mt-3,
.sf-my-3 {
  margin-top: 1rem !important;
}

.sf-mr-3,
.sf-mx-3 {
  margin-right: 1rem !important;
}

.sf-mb-3,
.sf-my-3 {
  margin-bottom: 1rem !important;
}

.sf-ml-3,
.sf-mx-3 {
  margin-left: 1rem !important;
}

.sf-m-4 {
  margin: 1.5rem !important;
}

.sf-mt-4,
.sf-my-4 {
  margin-top: 1.5rem !important;
}

.sf-mr-4,
.sf-mx-4 {
  margin-right: 1.5rem !important;
}

.sf-mb-4,
.sf-my-4 {
  margin-bottom: 1.5rem !important;
}

.sf-ml-4,
.sf-mx-4 {
  margin-left: 1.5rem !important;
}

.sf-m-5 {
  margin: 3rem !important;
}

.sf-mt-5,
.sf-my-5 {
  margin-top: 3rem !important;
}

.sf-mr-5,
.sf-mx-5 {
  margin-right: 3rem !important;
}

.sf-mb-5,
.sf-my-5 {
  margin-bottom: 3rem !important;
}

.sf-ml-5,
.sf-mx-5 {
  margin-left: 3rem !important;
}

.sf-p-0 {
  padding: 0 !important;
}

.sf-pt-0,
.sf-py-0 {
  padding-top: 0 !important;
}

.sf-pr-0,
.sf-px-0 {
  padding-right: 0 !important;
}

.sf-pb-0,
.sf-py-0 {
  padding-bottom: 0 !important;
}

.sf-pl-0,
.sf-px-0 {
  padding-left: 0 !important;
}

.sf-p-1 {
  padding: 0.25rem !important;
}

.sf-pt-1,
.sf-py-1 {
  padding-top: 0.25rem !important;
}

.sf-pr-1,
.sf-px-1 {
  padding-right: 0.25rem !important;
}

.sf-pb-1,
.sf-py-1 {
  padding-bottom: 0.25rem !important;
}

.sf-pl-1,
.sf-px-1 {
  padding-left: 0.25rem !important;
}

.sf-p-2 {
  padding: 0.5rem !important;
}

.sf-pt-2,
.sf-py-2 {
  padding-top: 0.5rem !important;
}

.sf-pr-2,
.sf-px-2 {
  padding-right: 0.5rem !important;
}

.sf-pb-2,
.sf-py-2 {
  padding-bottom: 0.5rem !important;
}

.sf-pl-2,
.sf-px-2 {
  padding-left: 0.5rem !important;
}

.sf-p-3 {
  padding: 1rem !important;
}

.sf-pt-3,
.sf-py-3 {
  padding-top: 1rem !important;
}

.sf-pr-3,
.sf-px-3 {
  padding-right: 1rem !important;
}

.sf-pb-3,
.sf-py-3 {
  padding-bottom: 1rem !important;
}

.sf-pl-3,
.sf-px-3 {
  padding-left: 1rem !important;
}

.sf-p-4 {
  padding: 1.5rem !important;
}

.sf-pt-4,
.sf-py-4 {
  padding-top: 1.5rem !important;
}

.sf-pr-4,
.sf-px-4 {
  padding-right: 1.5rem !important;
}

.sf-pb-4,
.sf-py-4 {
  padding-bottom: 1.5rem !important;
}

.sf-pl-4,
.sf-px-4 {
  padding-left: 1.5rem !important;
}

.sf-p-5 {
  padding: 3rem !important;
}

.sf-pt-5,
.sf-py-5 {
  padding-top: 3rem !important;
}

.sf-pr-5,
.sf-px-5 {
  padding-right: 3rem !important;
}

.sf-pb-5,
.sf-py-5 {
  padding-bottom: 3rem !important;
}

.sf-pl-5,
.sf-px-5 {
  padding-left: 3rem !important;
}

.sf-m-auto {
  margin: auto !important;
}

.sf-mt-auto,
.sf-my-auto {
  margin-top: auto !important;
}

.sf-mr-auto,
.sf-mx-auto {
  margin-right: auto !important;
}

.sf-mb-auto,
.sf-my-auto {
  margin-bottom: auto !important;
}

.sf-ml-auto,
.sf-mx-auto {
  margin-left: auto !important;
}

@media screen and (min-width: 576px) {
  .sf-m-sm-0 {
    margin: 0 !important;
  }

  .sf-mt-sm-0,
  .sf-my-sm-0 {
    margin-top: 0 !important;
  }

  .sf-mr-sm-0,
  .sf-mx-sm-0 {
    margin-right: 0 !important;
  }

  .sf-mb-sm-0,
  .sf-my-sm-0 {
    margin-bottom: 0 !important;
  }

  .sf-ml-sm-0,
  .sf-mx-sm-0 {
    margin-left: 0 !important;
  }

  .sf-m-sm-1 {
    margin: 0.25rem !important;
  }

  .sf-mt-sm-1,
  .sf-my-sm-1 {
    margin-top: 0.25rem !important;
  }

  .sf-mr-sm-1,
  .sf-mx-sm-1 {
    margin-right: 0.25rem !important;
  }

  .sf-mb-sm-1,
  .sf-my-sm-1 {
    margin-bottom: 0.25rem !important;
  }

  .sf-ml-sm-1,
  .sf-mx-sm-1 {
    margin-left: 0.25rem !important;
  }

  .sf-m-sm-2 {
    margin: 0.5rem !important;
  }

  .sf-mt-sm-2,
  .sf-my-sm-2 {
    margin-top: 0.5rem !important;
  }

  .sf-mr-sm-2,
  .sf-mx-sm-2 {
    margin-right: 0.5rem !important;
  }

  .sf-mb-sm-2,
  .sf-my-sm-2 {
    margin-bottom: 0.5rem !important;
  }

  .sf-ml-sm-2,
  .sf-mx-sm-2 {
    margin-left: 0.5rem !important;
  }

  .sf-m-sm-3 {
    margin: 1rem !important;
  }

  .sf-mt-sm-3,
  .sf-my-sm-3 {
    margin-top: 1rem !important;
  }

  .sf-mr-sm-3,
  .sf-mx-sm-3 {
    margin-right: 1rem !important;
  }

  .sf-mb-sm-3,
  .sf-my-sm-3 {
    margin-bottom: 1rem !important;
  }

  .sf-ml-sm-3,
  .sf-mx-sm-3 {
    margin-left: 1rem !important;
  }

  .sf-m-sm-4 {
    margin: 1.5rem !important;
  }

  .sf-mt-sm-4,
  .sf-my-sm-4 {
    margin-top: 1.5rem !important;
  }

  .sf-mr-sm-4,
  .sf-mx-sm-4 {
    margin-right: 1.5rem !important;
  }

  .sf-mb-sm-4,
  .sf-my-sm-4 {
    margin-bottom: 1.5rem !important;
  }

  .sf-ml-sm-4,
  .sf-mx-sm-4 {
    margin-left: 1.5rem !important;
  }

  .sf-m-sm-5 {
    margin: 3rem !important;
  }

  .sf-mt-sm-5,
  .sf-my-sm-5 {
    margin-top: 3rem !important;
  }

  .sf-mr-sm-5,
  .sf-mx-sm-5 {
    margin-right: 3rem !important;
  }

  .sf-mb-sm-5,
  .sf-my-sm-5 {
    margin-bottom: 3rem !important;
  }

  .sf-ml-sm-5,
  .sf-mx-sm-5 {
    margin-left: 3rem !important;
  }

  .sf-p-sm-0 {
    padding: 0 !important;
  }

  .sf-pt-sm-0,
  .sf-py-sm-0 {
    padding-top: 0 !important;
  }

  .sf-pr-sm-0,
  .sf-px-sm-0 {
    padding-right: 0 !important;
  }

  .sf-pb-sm-0,
  .sf-py-sm-0 {
    padding-bottom: 0 !important;
  }

  .sf-pl-sm-0,
  .sf-px-sm-0 {
    padding-left: 0 !important;
  }

  .sf-p-sm-1 {
    padding: 0.25rem !important;
  }

  .sf-pt-sm-1,
  .sf-py-sm-1 {
    padding-top: 0.25rem !important;
  }

  .sf-pr-sm-1,
  .sf-px-sm-1 {
    padding-right: 0.25rem !important;
  }

  .sf-pb-sm-1,
  .sf-py-sm-1 {
    padding-bottom: 0.25rem !important;
  }

  .sf-pl-sm-1,
  .sf-px-sm-1 {
    padding-left: 0.25rem !important;
  }

  .sf-p-sm-2 {
    padding: 0.5rem !important;
  }

  .sf-pt-sm-2,
  .sf-py-sm-2 {
    padding-top: 0.5rem !important;
  }

  .sf-pr-sm-2,
  .sf-px-sm-2 {
    padding-right: 0.5rem !important;
  }

  .sf-pb-sm-2,
  .sf-py-sm-2 {
    padding-bottom: 0.5rem !important;
  }

  .sf-pl-sm-2,
  .sf-px-sm-2 {
    padding-left: 0.5rem !important;
  }

  .sf-p-sm-3 {
    padding: 1rem !important;
  }

  .sf-pt-sm-3,
  .sf-py-sm-3 {
    padding-top: 1rem !important;
  }

  .sf-pr-sm-3,
  .sf-px-sm-3 {
    padding-right: 1rem !important;
  }

  .sf-pb-sm-3,
  .sf-py-sm-3 {
    padding-bottom: 1rem !important;
  }

  .sf-pl-sm-3,
  .sf-px-sm-3 {
    padding-left: 1rem !important;
  }

  .sf-p-sm-4 {
    padding: 1.5rem !important;
  }

  .sf-pt-sm-4,
  .sf-py-sm-4 {
    padding-top: 1.5rem !important;
  }

  .sf-pr-sm-4,
  .sf-px-sm-4 {
    padding-right: 1.5rem !important;
  }

  .sf-pb-sm-4,
  .sf-py-sm-4 {
    padding-bottom: 1.5rem !important;
  }

  .sf-pl-sm-4,
  .sf-px-sm-4 {
    padding-left: 1.5rem !important;
  }

  .sf-p-sm-5 {
    padding: 3rem !important;
  }

  .sf-pt-sm-5,
  .sf-py-sm-5 {
    padding-top: 3rem !important;
  }

  .sf-pr-sm-5,
  .sf-px-sm-5 {
    padding-right: 3rem !important;
  }

  .sf-pb-sm-5,
  .sf-py-sm-5 {
    padding-bottom: 3rem !important;
  }

  .sf-pl-sm-5,
  .sf-px-sm-5 {
    padding-left: 3rem !important;
  }

  .sf-m-sm-auto {
    margin: auto !important;
  }

  .sf-mt-sm-auto,
  .sf-my-sm-auto {
    margin-top: auto !important;
  }

  .sf-mr-sm-auto,
  .sf-mx-sm-auto {
    margin-right: auto !important;
  }

  .sf-mb-sm-auto,
  .sf-my-sm-auto {
    margin-bottom: auto !important;
  }

  .sf-ml-sm-auto,
  .sf-mx-sm-auto {
    margin-left: auto !important;
  }
}
@media screen and (min-width: 768px) {
  .sf-m-md-0 {
    margin: 0 !important;
  }

  .sf-mt-md-0,
  .sf-my-md-0 {
    margin-top: 0 !important;
  }

  .sf-mr-md-0,
  .sf-mx-md-0 {
    margin-right: 0 !important;
  }

  .sf-mb-md-0,
  .sf-my-md-0 {
    margin-bottom: 0 !important;
  }

  .sf-ml-md-0,
  .sf-mx-md-0 {
    margin-left: 0 !important;
  }

  .sf-m-md-1 {
    margin: 0.25rem !important;
  }

  .sf-mt-md-1,
  .sf-my-md-1 {
    margin-top: 0.25rem !important;
  }

  .sf-mr-md-1,
  .sf-mx-md-1 {
    margin-right: 0.25rem !important;
  }

  .sf-mb-md-1,
  .sf-my-md-1 {
    margin-bottom: 0.25rem !important;
  }

  .sf-ml-md-1,
  .sf-mx-md-1 {
    margin-left: 0.25rem !important;
  }

  .sf-m-md-2 {
    margin: 0.5rem !important;
  }

  .sf-mt-md-2,
  .sf-my-md-2 {
    margin-top: 0.5rem !important;
  }

  .sf-mr-md-2,
  .sf-mx-md-2 {
    margin-right: 0.5rem !important;
  }

  .sf-mb-md-2,
  .sf-my-md-2 {
    margin-bottom: 0.5rem !important;
  }

  .sf-ml-md-2,
  .sf-mx-md-2 {
    margin-left: 0.5rem !important;
  }

  .sf-m-md-3 {
    margin: 1rem !important;
  }

  .sf-mt-md-3,
  .sf-my-md-3 {
    margin-top: 1rem !important;
  }

  .sf-mr-md-3,
  .sf-mx-md-3 {
    margin-right: 1rem !important;
  }

  .sf-mb-md-3,
  .sf-my-md-3 {
    margin-bottom: 1rem !important;
  }

  .sf-ml-md-3,
  .sf-mx-md-3 {
    margin-left: 1rem !important;
  }

  .sf-m-md-4 {
    margin: 1.5rem !important;
  }

  .sf-mt-md-4,
  .sf-my-md-4 {
    margin-top: 1.5rem !important;
  }

  .sf-mr-md-4,
  .sf-mx-md-4 {
    margin-right: 1.5rem !important;
  }

  .sf-mb-md-4,
  .sf-my-md-4 {
    margin-bottom: 1.5rem !important;
  }

  .sf-ml-md-4,
  .sf-mx-md-4 {
    margin-left: 1.5rem !important;
  }

  .sf-m-md-5 {
    margin: 3rem !important;
  }

  .sf-mt-md-5,
  .sf-my-md-5 {
    margin-top: 3rem !important;
  }

  .sf-mr-md-5,
  .sf-mx-md-5 {
    margin-right: 3rem !important;
  }

  .sf-mb-md-5,
  .sf-my-md-5 {
    margin-bottom: 3rem !important;
  }

  .sf-ml-md-5,
  .sf-mx-md-5 {
    margin-left: 3rem !important;
  }

  .sf-p-md-0 {
    padding: 0 !important;
  }

  .sf-pt-md-0,
  .sf-py-md-0 {
    padding-top: 0 !important;
  }

  .sf-pr-md-0,
  .sf-px-md-0 {
    padding-right: 0 !important;
  }

  .sf-pb-md-0,
  .sf-py-md-0 {
    padding-bottom: 0 !important;
  }

  .sf-pl-md-0,
  .sf-px-md-0 {
    padding-left: 0 !important;
  }

  .sf-p-md-1 {
    padding: 0.25rem !important;
  }

  .sf-pt-md-1,
  .sf-py-md-1 {
    padding-top: 0.25rem !important;
  }

  .sf-pr-md-1,
  .sf-px-md-1 {
    padding-right: 0.25rem !important;
  }

  .sf-pb-md-1,
  .sf-py-md-1 {
    padding-bottom: 0.25rem !important;
  }

  .sf-pl-md-1,
  .sf-px-md-1 {
    padding-left: 0.25rem !important;
  }

  .sf-p-md-2 {
    padding: 0.5rem !important;
  }

  .sf-pt-md-2,
  .sf-py-md-2 {
    padding-top: 0.5rem !important;
  }

  .sf-pr-md-2,
  .sf-px-md-2 {
    padding-right: 0.5rem !important;
  }

  .sf-pb-md-2,
  .sf-py-md-2 {
    padding-bottom: 0.5rem !important;
  }

  .sf-pl-md-2,
  .sf-px-md-2 {
    padding-left: 0.5rem !important;
  }

  .sf-p-md-3 {
    padding: 1rem !important;
  }

  .sf-pt-md-3,
  .sf-py-md-3 {
    padding-top: 1rem !important;
  }

  .sf-pr-md-3,
  .sf-px-md-3 {
    padding-right: 1rem !important;
  }

  .sf-pb-md-3,
  .sf-py-md-3 {
    padding-bottom: 1rem !important;
  }

  .sf-pl-md-3,
  .sf-px-md-3 {
    padding-left: 1rem !important;
  }

  .sf-p-md-4 {
    padding: 1.5rem !important;
  }

  .sf-pt-md-4,
  .sf-py-md-4 {
    padding-top: 1.5rem !important;
  }

  .sf-pr-md-4,
  .sf-px-md-4 {
    padding-right: 1.5rem !important;
  }

  .sf-pb-md-4,
  .sf-py-md-4 {
    padding-bottom: 1.5rem !important;
  }

  .sf-pl-md-4,
  .sf-px-md-4 {
    padding-left: 1.5rem !important;
  }

  .sf-p-md-5 {
    padding: 3rem !important;
  }

  .sf-pt-md-5,
  .sf-py-md-5 {
    padding-top: 3rem !important;
  }

  .sf-pr-md-5,
  .sf-px-md-5 {
    padding-right: 3rem !important;
  }

  .sf-pb-md-5,
  .sf-py-md-5 {
    padding-bottom: 3rem !important;
  }

  .sf-pl-md-5,
  .sf-px-md-5 {
    padding-left: 3rem !important;
  }

  .sf-m-md-auto {
    margin: auto !important;
  }

  .sf-mt-md-auto,
  .sf-my-md-auto {
    margin-top: auto !important;
  }

  .sf-mr-md-auto,
  .sf-mx-md-auto {
    margin-right: auto !important;
  }

  .sf-mb-md-auto,
  .sf-my-md-auto {
    margin-bottom: auto !important;
  }

  .sf-ml-md-auto,
  .sf-mx-md-auto {
    margin-left: auto !important;
  }
}
@media screen and (min-width: 992px) {
  .sf-m-lg-0 {
    margin: 0 !important;
  }

  .sf-mt-lg-0,
  .sf-my-lg-0 {
    margin-top: 0 !important;
  }

  .sf-mr-lg-0,
  .sf-mx-lg-0 {
    margin-right: 0 !important;
  }

  .sf-mb-lg-0,
  .sf-my-lg-0 {
    margin-bottom: 0 !important;
  }

  .sf-ml-lg-0,
  .sf-mx-lg-0 {
    margin-left: 0 !important;
  }

  .sf-m-lg-1 {
    margin: 0.25rem !important;
  }

  .sf-mt-lg-1,
  .sf-my-lg-1 {
    margin-top: 0.25rem !important;
  }

  .sf-mr-lg-1,
  .sf-mx-lg-1 {
    margin-right: 0.25rem !important;
  }

  .sf-mb-lg-1,
  .sf-my-lg-1 {
    margin-bottom: 0.25rem !important;
  }

  .sf-ml-lg-1,
  .sf-mx-lg-1 {
    margin-left: 0.25rem !important;
  }

  .sf-m-lg-2 {
    margin: 0.5rem !important;
  }

  .sf-mt-lg-2,
  .sf-my-lg-2 {
    margin-top: 0.5rem !important;
  }

  .sf-mr-lg-2,
  .sf-mx-lg-2 {
    margin-right: 0.5rem !important;
  }

  .sf-mb-lg-2,
  .sf-my-lg-2 {
    margin-bottom: 0.5rem !important;
  }

  .sf-ml-lg-2,
  .sf-mx-lg-2 {
    margin-left: 0.5rem !important;
  }

  .sf-m-lg-3 {
    margin: 1rem !important;
  }

  .sf-mt-lg-3,
  .sf-my-lg-3 {
    margin-top: 1rem !important;
  }

  .sf-mr-lg-3,
  .sf-mx-lg-3 {
    margin-right: 1rem !important;
  }

  .sf-mb-lg-3,
  .sf-my-lg-3 {
    margin-bottom: 1rem !important;
  }

  .sf-ml-lg-3,
  .sf-mx-lg-3 {
    margin-left: 1rem !important;
  }

  .sf-m-lg-4 {
    margin: 1.5rem !important;
  }

  .sf-mt-lg-4,
  .sf-my-lg-4 {
    margin-top: 1.5rem !important;
  }

  .sf-mr-lg-4,
  .sf-mx-lg-4 {
    margin-right: 1.5rem !important;
  }

  .sf-mb-lg-4,
  .sf-my-lg-4 {
    margin-bottom: 1.5rem !important;
  }

  .sf-ml-lg-4,
  .sf-mx-lg-4 {
    margin-left: 1.5rem !important;
  }

  .sf-m-lg-5 {
    margin: 3rem !important;
  }

  .sf-mt-lg-5,
  .sf-my-lg-5 {
    margin-top: 3rem !important;
  }

  .sf-mr-lg-5,
  .sf-mx-lg-5 {
    margin-right: 3rem !important;
  }

  .sf-mb-lg-5,
  .sf-my-lg-5 {
    margin-bottom: 3rem !important;
  }

  .sf-ml-lg-5,
  .sf-mx-lg-5 {
    margin-left: 3rem !important;
  }

  .sf-p-lg-0 {
    padding: 0 !important;
  }

  .sf-pt-lg-0,
  .sf-py-lg-0 {
    padding-top: 0 !important;
  }

  .sf-pr-lg-0,
  .sf-px-lg-0 {
    padding-right: 0 !important;
  }

  .sf-pb-lg-0,
  .sf-py-lg-0 {
    padding-bottom: 0 !important;
  }

  .sf-pl-lg-0,
  .sf-px-lg-0 {
    padding-left: 0 !important;
  }

  .sf-p-lg-1 {
    padding: 0.25rem !important;
  }

  .sf-pt-lg-1,
  .sf-py-lg-1 {
    padding-top: 0.25rem !important;
  }

  .sf-pr-lg-1,
  .sf-px-lg-1 {
    padding-right: 0.25rem !important;
  }

  .sf-pb-lg-1,
  .sf-py-lg-1 {
    padding-bottom: 0.25rem !important;
  }

  .sf-pl-lg-1,
  .sf-px-lg-1 {
    padding-left: 0.25rem !important;
  }

  .sf-p-lg-2 {
    padding: 0.5rem !important;
  }

  .sf-pt-lg-2,
  .sf-py-lg-2 {
    padding-top: 0.5rem !important;
  }

  .sf-pr-lg-2,
  .sf-px-lg-2 {
    padding-right: 0.5rem !important;
  }

  .sf-pb-lg-2,
  .sf-py-lg-2 {
    padding-bottom: 0.5rem !important;
  }

  .sf-pl-lg-2,
  .sf-px-lg-2 {
    padding-left: 0.5rem !important;
  }

  .sf-p-lg-3 {
    padding: 1rem !important;
  }

  .sf-pt-lg-3,
  .sf-py-lg-3 {
    padding-top: 1rem !important;
  }

  .sf-pr-lg-3,
  .sf-px-lg-3 {
    padding-right: 1rem !important;
  }

  .sf-pb-lg-3,
  .sf-py-lg-3 {
    padding-bottom: 1rem !important;
  }

  .sf-pl-lg-3,
  .sf-px-lg-3 {
    padding-left: 1rem !important;
  }

  .sf-p-lg-4 {
    padding: 1.5rem !important;
  }

  .sf-pt-lg-4,
  .sf-py-lg-4 {
    padding-top: 1.5rem !important;
  }

  .sf-pr-lg-4,
  .sf-px-lg-4 {
    padding-right: 1.5rem !important;
  }

  .sf-pb-lg-4,
  .sf-py-lg-4 {
    padding-bottom: 1.5rem !important;
  }

  .sf-pl-lg-4,
  .sf-px-lg-4 {
    padding-left: 1.5rem !important;
  }

  .sf-p-lg-5 {
    padding: 3rem !important;
  }

  .sf-pt-lg-5,
  .sf-py-lg-5 {
    padding-top: 3rem !important;
  }

  .sf-pr-lg-5,
  .sf-px-lg-5 {
    padding-right: 3rem !important;
  }

  .sf-pb-lg-5,
  .sf-py-lg-5 {
    padding-bottom: 3rem !important;
  }

  .sf-pl-lg-5,
  .sf-px-lg-5 {
    padding-left: 3rem !important;
  }

  .sf-m-lg-auto {
    margin: auto !important;
  }

  .sf-mt-lg-auto,
  .sf-my-lg-auto {
    margin-top: auto !important;
  }

  .sf-mr-lg-auto,
  .sf-mx-lg-auto {
    margin-right: auto !important;
  }

  .sf-mb-lg-auto,
  .sf-my-lg-auto {
    margin-bottom: auto !important;
  }

  .sf-ml-lg-auto,
  .sf-mx-lg-auto {
    margin-left: auto !important;
  }
}
@media screen and (min-width: 1200px) {
  .sf-m-xl-0 {
    margin: 0 !important;
  }

  .sf-mt-xl-0,
  .sf-my-xl-0 {
    margin-top: 0 !important;
  }

  .sf-mr-xl-0,
  .sf-mx-xl-0 {
    margin-right: 0 !important;
  }

  .sf-mb-xl-0,
  .sf-my-xl-0 {
    margin-bottom: 0 !important;
  }

  .sf-ml-xl-0,
  .sf-mx-xl-0 {
    margin-left: 0 !important;
  }

  .sf-m-xl-1 {
    margin: 0.25rem !important;
  }

  .sf-mt-xl-1,
  .sf-my-xl-1 {
    margin-top: 0.25rem !important;
  }

  .sf-mr-xl-1,
  .sf-mx-xl-1 {
    margin-right: 0.25rem !important;
  }

  .sf-mb-xl-1,
  .sf-my-xl-1 {
    margin-bottom: 0.25rem !important;
  }

  .sf-ml-xl-1,
  .sf-mx-xl-1 {
    margin-left: 0.25rem !important;
  }

  .sf-m-xl-2 {
    margin: 0.5rem !important;
  }

  .sf-mt-xl-2,
  .sf-my-xl-2 {
    margin-top: 0.5rem !important;
  }

  .sf-mr-xl-2,
  .sf-mx-xl-2 {
    margin-right: 0.5rem !important;
  }

  .sf-mb-xl-2,
  .sf-my-xl-2 {
    margin-bottom: 0.5rem !important;
  }

  .sf-ml-xl-2,
  .sf-mx-xl-2 {
    margin-left: 0.5rem !important;
  }

  .sf-m-xl-3 {
    margin: 1rem !important;
  }

  .sf-mt-xl-3,
  .sf-my-xl-3 {
    margin-top: 1rem !important;
  }

  .sf-mr-xl-3,
  .sf-mx-xl-3 {
    margin-right: 1rem !important;
  }

  .sf-mb-xl-3,
  .sf-my-xl-3 {
    margin-bottom: 1rem !important;
  }

  .sf-ml-xl-3,
  .sf-mx-xl-3 {
    margin-left: 1rem !important;
  }

  .sf-m-xl-4 {
    margin: 1.5rem !important;
  }

  .sf-mt-xl-4,
  .sf-my-xl-4 {
    margin-top: 1.5rem !important;
  }

  .sf-mr-xl-4,
  .sf-mx-xl-4 {
    margin-right: 1.5rem !important;
  }

  .sf-mb-xl-4,
  .sf-my-xl-4 {
    margin-bottom: 1.5rem !important;
  }

  .sf-ml-xl-4,
  .sf-mx-xl-4 {
    margin-left: 1.5rem !important;
  }

  .sf-m-xl-5 {
    margin: 3rem !important;
  }

  .sf-mt-xl-5,
  .sf-my-xl-5 {
    margin-top: 3rem !important;
  }

  .sf-mr-xl-5,
  .sf-mx-xl-5 {
    margin-right: 3rem !important;
  }

  .sf-mb-xl-5,
  .sf-my-xl-5 {
    margin-bottom: 3rem !important;
  }

  .sf-ml-xl-5,
  .sf-mx-xl-5 {
    margin-left: 3rem !important;
  }

  .sf-p-xl-0 {
    padding: 0 !important;
  }

  .sf-pt-xl-0,
  .sf-py-xl-0 {
    padding-top: 0 !important;
  }

  .sf-pr-xl-0,
  .sf-px-xl-0 {
    padding-right: 0 !important;
  }

  .sf-pb-xl-0,
  .sf-py-xl-0 {
    padding-bottom: 0 !important;
  }

  .sf-pl-xl-0,
  .sf-px-xl-0 {
    padding-left: 0 !important;
  }

  .sf-p-xl-1 {
    padding: 0.25rem !important;
  }

  .sf-pt-xl-1,
  .sf-py-xl-1 {
    padding-top: 0.25rem !important;
  }

  .sf-pr-xl-1,
  .sf-px-xl-1 {
    padding-right: 0.25rem !important;
  }

  .sf-pb-xl-1,
  .sf-py-xl-1 {
    padding-bottom: 0.25rem !important;
  }

  .sf-pl-xl-1,
  .sf-px-xl-1 {
    padding-left: 0.25rem !important;
  }

  .sf-p-xl-2 {
    padding: 0.5rem !important;
  }

  .sf-pt-xl-2,
  .sf-py-xl-2 {
    padding-top: 0.5rem !important;
  }

  .sf-pr-xl-2,
  .sf-px-xl-2 {
    padding-right: 0.5rem !important;
  }

  .sf-pb-xl-2,
  .sf-py-xl-2 {
    padding-bottom: 0.5rem !important;
  }

  .sf-pl-xl-2,
  .sf-px-xl-2 {
    padding-left: 0.5rem !important;
  }

  .sf-p-xl-3 {
    padding: 1rem !important;
  }

  .sf-pt-xl-3,
  .sf-py-xl-3 {
    padding-top: 1rem !important;
  }

  .sf-pr-xl-3,
  .sf-px-xl-3 {
    padding-right: 1rem !important;
  }

  .sf-pb-xl-3,
  .sf-py-xl-3 {
    padding-bottom: 1rem !important;
  }

  .sf-pl-xl-3,
  .sf-px-xl-3 {
    padding-left: 1rem !important;
  }

  .sf-p-xl-4 {
    padding: 1.5rem !important;
  }

  .sf-pt-xl-4,
  .sf-py-xl-4 {
    padding-top: 1.5rem !important;
  }

  .sf-pr-xl-4,
  .sf-px-xl-4 {
    padding-right: 1.5rem !important;
  }

  .sf-pb-xl-4,
  .sf-py-xl-4 {
    padding-bottom: 1.5rem !important;
  }

  .sf-pl-xl-4,
  .sf-px-xl-4 {
    padding-left: 1.5rem !important;
  }

  .sf-p-xl-5 {
    padding: 3rem !important;
  }

  .sf-pt-xl-5,
  .sf-py-xl-5 {
    padding-top: 3rem !important;
  }

  .sf-pr-xl-5,
  .sf-px-xl-5 {
    padding-right: 3rem !important;
  }

  .sf-pb-xl-5,
  .sf-py-xl-5 {
    padding-bottom: 3rem !important;
  }

  .sf-pl-xl-5,
  .sf-px-xl-5 {
    padding-left: 3rem !important;
  }

  .sf-m-xl-auto {
    margin: auto !important;
  }

  .sf-mt-xl-auto,
  .sf-my-xl-auto {
    margin-top: auto !important;
  }

  .sf-mr-xl-auto,
  .sf-mx-xl-auto {
    margin-right: auto !important;
  }

  .sf-mb-xl-auto,
  .sf-my-xl-auto {
    margin-bottom: auto !important;
  }

  .sf-ml-xl-auto,
  .sf-mx-xl-auto {
    margin-left: auto !important;
  }
}
.sf-border {
  border: 1px solid $greyLight !important;
}

.sf-border-2 {
  border: 2px solid $greyLight !important;
}

.sf-border-top {
  border-top: 1px solid $greyLight !important;
}

.sf-border-top-2 {
  border-top: 2px solid $greyLight !important;
}

.sf-border-right {
  border-right: 1px solid $greyLight !important;
}

.sf-border-bottom {
  border-bottom: 1px solid $greyLight !important;
}

.sf-border-bottom-2 {
  border-bottom: 2px solid $greyLight !important;
}

.sf-border-left {
  border-left: 1px solid $greyLight !important;
}

.sf-border-left-2 {
  border-left: 2px solid $greyLight !important;
}

.sf-border-0 {
  border: 0 !important;
}

.sf-border-top-0 {
  border-top: 0 !important;
}

.sf-border-right-0 {
  border-right: 0 !important;
}

.sf-border-bottom-0 {
  border-bottom: 0 !important;
}

.sf-border-left-0 {
  border-left: 0 !important;
}

.sf-border-primary {
  border-color: $primaryColor !important;
}

.sf-border-secondary {
  border-color: $secondaryColor !important;
}

.sf-border-success {
  border-color: $successColor !important;
}

.sf-border-danger {
  border-color: $dangerColor !important;
}

.sf-border-warning {
  border-color: $warningColor !important;
}

.sf-border-info {
  border-color: $infoColor !important;
}

.sf-border-default {
  border-color: $defaultColor !important;
}

.sf-border-light {
  border-color: $lightColor !important;
}

.sf-border-white {
  border-color: $whiteColor !important;
}

.sf-border-radius {
  border-radius: 4px !important;
}

.sf-border-radius-top {
  border-top-left-radius: 4px !important;
  border-top-right-radius: 4px !important;
}

.sf-border-radius-right {
  border-top-right-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
}

.sf-border-radius-bottom {
  border-bottom-right-radius: 4px !important;
  border-bottom-left-radius: 4px !important;
}

.sf-border-radius-left {
  border-top-left-radius: 4px !important;
  border-bottom-left-radius: 4px !important;
}

.sf-border-radius-circle {
  border-radius: 50% !important;
}

.sf-border-radius-top-0 {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.sf-border-radius-right-0 {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.sf-border-radius-bottom-0 {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.sf-border-radius-left-0 {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.sf-border-radius-0 {
  border-radius: 0 !important;
}

.sf-rounded-circle {
  border-radius: 50% !important;
}

.sf-bg-primary {
  background-color: $primaryColor !important;
}

.sf-bg-secondary {
  background-color: $secondaryColor !important;
}

.sf-bg-success {
  background-color: $successColor !important;
}

.sf-bg-danger {
  background-color: $dangerColor !important;
}

.sf-bg-danger-opacity {
  background-color: $dangerColor !important;
}

.sf-bg-warning {
  background-color: $warningColor !important;
}

.sf-bg-info {
  background-color: $infoColor !important;
}

.sf-bg-default {
  background-color: $whiteColor !important;
}

.sf-bg-light {
  background-color: $lightColor !important;
}

.sf-bg-white {
  background-color: $whiteColor !important;
}

.sf-bg-transparent {
  background-color: transparent !important;
}

.sf-boxshadow-none {
  box-shadow: none !important;
}

.sf-overflow-auto {
  overflow: auto !important;
}

.sf-overflow-hidden {
  overflow: hidden !important;
}

.sf-card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: $whiteColor;
  background-clip: border-box;
  border: 1px solid $cardColor;
  border-radius: 4px;
  margin-bottom: 24px;
}

.sf-card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  border-bottom: 1px solid $cardColor;
  font-size: 1.125rem;
}

.sf-card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem;
}

.sf-card-footer {
  padding: 0.75rem 1.25rem;
  margin-top: 0;
  border-top: 1px solid $cardColor;
  font-size: 1.125rem;
}

.sf-card-title {
  margin-bottom: 0.75rem;
}

.sf-card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0;
}

.sf-card-text:last-child {
  margin-bottom: 0;
}

.sf-card-icon {
  width: 40px;
  height: 40px;
}

.sf-dropdown {
  position: relative;
}

.sf-position-relative {
  position: relative !important;
}
.sf-position-absolute {
  position: absolute !important;
}

.sf-position-fixed {
  position: fixed !important;
}
.sf-position-initial {
  position: initial !important;
}

.sf-centerAbsolute-vertical {
  transform: translateY(-50%);
  top: 50%;
}
.sf-centerAbsolute-horizontal {
  transform: translateX(-50%);
  left: 50%;
}

.sf-centerAbsolute-xy {
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
}

.sf-dropdown-menu {
  position: absolute;
  top: 100%;
  right: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 12rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: $linkMenuColor;
  text-align: left;
  list-style: none;
  background-color: $bgMenuColor;
  background-clip: padding-box;
  border: 0 solid;
  border-radius: 4px;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
}
.sf-dropdown-menu.show {
  display: block;
  z-index: 6;
  pointer-events: auto;
  -webkit-animation: 0.25s forwards dropdown;
  animation: 0.25s forwards dropdown;
}

.sf-dropdown-menu-sm {
  min-width: 100px;
}

.sf-dropdown-menu-lg {
  min-width: 320px;
}

.sf-dropdown-menu-xl {
  min-width: 420px;
}

.sf-dropdown-item {
  display: block;
  width: 100%;
  padding: 0.5rem 1rem;
  clear: both;
  font-weight: 400;
  color: $linkMenuColor;
  transition: all 180ms ease-in 0s;
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.sf-dropdown-item:hover {
  text-decoration: none;
  background-color: $borderMenuColor;
  color: $whiteColor;
}
.sf-dropdown-item i {
  margin-right: 0.5rem;
}

.sf-dropdown-header {
  display: block;
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  font-size: 0.75rem;
  color: $whiteColor;
  white-space: nowrap;
}

.sf-dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid $borderMenuColor;
}

.sf-dropdown-menu-left {
  right: auto;
  left: 0;
}

.sf-dropdown-menu-right {
  right: 0;
  left: auto;
}

@media screen and (min-width: 576px) {
  .sf-dropdown-menu-sm-left {
    right: auto;
    left: 0;
  }

  .sf-dropdown-menu-sm-right {
    right: 0;
    left: auto;
  }
}
@media screen and (min-width: 768px) {
  .sf-dropdown-menu-md-left {
    right: auto;
    left: 0;
  }

  .sf-dropdown-menu-md-right {
    right: 0;
    left: auto;
  }
}
@media screen and (min-width: 992px) {
  .sf-dropdown-menu-lg-left {
    right: auto;
    left: 0;
  }

  .sf-dropdown-menu-lg-right {
    right: 0;
    left: auto;
  }
}
@media screen and (min-width: 1200px) {
  .sf-dropdown-menu-xl-left {
    right: auto;
    left: 0;
  }

  .sf-dropdown-menu-xl-right {
    right: 0;
    left: auto;
  }
}
@keyframes dropdown {
  0% {
    opacity: 0;
    transform: translate(0, 10px) perspective(200px) rotateX(-2deg);
    transition: visibility 0.25s, opacity 0.25s, transform 0.25s;
  }
  100% {
    transform: translate(0, 0);
    opacity: 1;
  }
}
.sf-btn {
  display: inline-block;
  font-family: "Nunito Sans", sans-serif;
  font-size: 1rem;
  font-weight: 600;
  white-space: nowrap;
  border: 1px solid transparent;
  height: 46px;
  border-radius: 4px;
  transition: all 180ms ease-in 0s;
  text-align: center;
  vertical-align: middle;
  background-color: transparent;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: auto;
  margin: 0;
  padding: 0.625rem 1.25rem;
  letter-spacing: 0.025em;
  cursor: pointer;
}
.sf-btn:hover {
  text-decoration: none;
}

.sf-btn[disabled] {
  background-color: $buttonDisableBgColor;
  border-color: $buttonDisableBgColor;
  cursor: not-allowed;
  color: $buttonDisableColor;
  opacity: 0.7;
  &:hover {
    background-color: $buttonDisableBgColor;
    border-color: $buttonDisableBgColor;
    color: $buttonDisableColor;
  }
}

a.sf-btn {
  line-height: 26px;
}

a.sf-btn.sf-btn-sm {
  line-height: initial;
}

.sf-btn-primary {
  color: $whiteColor;
  background-color: $btnPrimary;
  border-color: $btnPrimary;
}
.sf-btn-primary:hover {
  background-color: $btnPrimaryHover;
  border-color: $btnPrimaryHover;
  color: $whiteColor;
}

.sf-btn-secondary {
  color: $whiteColor;
  background-color: $secondaryColor;
  border-color: $secondaryColor;
}
.sf-btn-secondary:hover {
  background-color: #007371;
  border-color: #007371;
  color: $whiteColor;
}

.sf-btn-success {
  color: $whiteColor;
  background-color: $successColor;
  border-color: $successColor;
}
.sf-btn-success:hover {
  background-color: #32ab52;
  border-color: #32ab52;
  color: $whiteColor;
}

.sf-btn-danger {
  color: $whiteColor;
  background-color: $dangerColor;
  border-color: $dangerColor;
}
.sf-btn-danger:hover {
  background-color: #e22f3a;
  border-color: #e22f3a;
  color: $whiteColor;
}

.sf-btn-warning {
  color: $whiteColor;
  background-color: $warningColor;
  border-color: $warningColor;
}
.sf-btn-warning:hover {
  background-color: #da8f21;
  border-color: #da8f21;
  color: $whiteColor;
}

.sf-btn-info {
  color: $whiteColor;
  background-color: $infoColor;
  border-color: $infoColor;
}
.sf-btn-info:hover {
  background-color: #0c83e2;
  border-color: #0c83e2;
  color: $whiteColor;
}

.sf-btn-default {
  color: $whiteColor;
  background-color: $defaultColor;
  border-color: $defaultColor;
}
.sf-btn-default:hover {
  background-color: #0e1b30;
  border-color: #0e1b30;
  color: $whiteColor;
}

.sf-btn-light {
  color: $whiteColor;
  background-color: $lightColor;
  border-color: $lightColor;
}
.sf-btn-light:hover {
  background-color: #cdcdcd;
  border-color: #cdcdcd;
  color: $whiteColor;
}

.sf-btn-outline-primary {
  color: $primaryColor;
  background-color: transparent;
  border-color: $primaryColor;
}
.sf-btn-outline-primary:hover {
  color: $whiteColor;
  background-color: $primaryColor;
  border-color: $primaryColor;
}

.sf-btn-outline-secondary {
  color: $secondaryColor;
  background-color: transparent;
  border-color: $secondaryColor;
}
.sf-btn-outline-secondary:hover {
  color: $whiteColor;
  background-color: $secondaryColor;
  border-color: $secondaryColor;
}

.sf-btn-outline-success {
  color: $successColor;
  background-color: transparent;
  border-color: $successColor;
}
.sf-btn-outline-success:hover {
  color: $whiteColor;
  background-color: $successColor;
  border-color: $successColor;
}

.sf-btn-outline-danger {
  color: $dangerColor;
  background-color: transparent;
  border-color: $dangerColor;
}
.sf-btn-outline-danger:hover {
  color: $whiteColor;
  background-color: $dangerColor;
  border-color: $dangerColor;
}

.sf-btn-outline-warning {
  color: $warningColor;
  background-color: transparent;
  border-color: $warningColor;
}
.sf-btn-outline-warning:hover {
  color: $whiteColor;
  background-color: $warningColor;
  border-color: $warningColor;
}

.sf-btn-outline-info {
  color: $infoColor;
  background-color: transparent;
  border-color: $infoColor;
}
.sf-btn-outline-info:hover {
  color: $whiteColor;
  background-color: $infoColor;
  border-color: $infoColor;
}

.sf-btn-outline-default {
  color: $defaultColor;
  background-color: transparent;
  border-color: $defaultColor;
}
.sf-btn-outline-default:hover {
  color: $whiteColor;
  background-color: $defaultColor;
  border-color: $defaultColor;
}

.sf-btn-outline-light {
  color: $lightColor;
  background-color: transparent;
  border-color: $lightColor;
}
.sf-btn-outline-light:hover {
  color: $whiteColor;
  background-color: $lightColor;
  border-color: $lightColor;
}

.sf-btn-link {
  background-color: transparent;
  color: $secondaryColor;
  text-transform: none;
  font-weight: 400;
  width: auto;
  padding: 0.75rem 1rem;
}
.sf-btn-link:hover,
.sf-btn-link:focus,
.sf-btn-link.ui-state-hover,
.sf-btn-link.ui-state-focus {
  text-decoration: underline !important;
}

.sf-btn-block {
  display: block;
  width: 100%;
}

.sf-width-100 {
  width: 100% !important;
}

@media screen and (max-width: 576px) {
  .sf-btn-sm-block {
    display: block;
    width: 100%;
  }
}
@media screen and (max-width: 768px) {
  .sf-btn-md-block {
    display: block;
    width: 100%;
  }
}
@media screen and (max-width: 992px) {
  .sf-btn-lg-block {
    display: block;
    width: 100%;
  }
}
@media screen and (max-width: 1200px) {
  .sf-btn-xl-block {
    display: block;
    width: 100%;
  }
}
.sf-btn-sm {
  padding: 0.25rem 1rem;
  font-size: 0.875rem;
  height: 30px;
  border-radius: 0.2rem;
}

.sf-form-group {
  margin-bottom: 1.5rem;
}

.sf-label {
  display: inline-block;
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
}

h1,
h2,
h3,
h4,
h5,
h6,
.sf-h1,
.sf-h2,
.sf-h3,
.sf-h4,
.sf-h5,
.sf-h6 {
  margin-bottom: 0.5rem;
  font-family: "Nunito Sans", sans-serif;
  line-height: 1.5;
  color: $standardTextColor;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700;
}

h1,
.sf-h1 {
  font-size: 1.75rem;
}

.sf-h1-lg {
  font-size: 3.75rem;
}

.sf-p-sm {
  font-size: 1.5rem !important;
}

.sf-p-md {
  font-size: 1.75rem !important;
}

h2,
.sf-h2 {
  font-size: 1.5rem;
}

h3,
.sf-h3 {
  font-size: 1.25rem;
}

h4,
.sf-h4 {
  font-size: 1rem;
}

h5,
.sf-h5 {
  font-size: 0.875rem;
}

h6,
.sf-h6 {
  font-size: 0.75rem;
}

.sf-height-large-50 {
  height: 50px;
}

.sf-input-group {
  position: relative;
  display: flex;
  align-items: stretch;
  width: 100%;
}
.sf-input-group .sf-input-group-text {
  display: flex;
  align-items: center;
  height: 46px;
  padding: 0.625rem 0.75rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  text-decoration: none;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

.sf-input-group-prepend,
.sf-input-group-append {
  display: flex;
}
.sf-input-group-prepend .sf-btn,
.sf-input-group-append .sf-btn {
  position: relative;
  z-index: 2;
}

.sf-input-group-prepend {
  margin-right: -1px;
}

.sf-input-group-append {
  margin-left: -1px;
}

.sf-input-group > .sf-input-group-prepend > .sf-btn,
.sf-input-group > .sf-input-group-prepend > .sf-input-group-text,
.sf-input-group > .sf-input-group-append:not(:last-child) > .sf-btn,
.sf-input-group
  > .sf-input-group-append:not(:last-child)
  > .sf-input-group-text,
.sf-input-group > .ui-inputfield:not(:last-child),
.sf-input-group > .ui-selectonemenu:not(:last-child),
.sf-input-group > .ui-autocomplete:not(:last-child) .ui-autocomplete-input {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}

.sf-input-group > .sf-input-group-append > .sf-btn,
.sf-input-group > .sf-input-group-append > .sf-input-group-text,
.sf-input-group > .sf-input-group-prepend:not(:first-child) > .sf-btn,
.sf-input-group
  > .sf-input-group-prepend:not(:first-child)
  > .sf-input-group-text,
.sf-input-group > .ui-inputfield:not(:first-child),
.sf-input-group > .ui-selectonemenu:not(:first-child),
.sf-input-group > .ui-autocomplete:not(:first-child) .ui-autocomplete-input {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}

.sf-btn-group,
.sf-btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.sf-btn-group > .sf-btn,
.sf-btn-group-vertical > .sf-btn {
  position: relative;
  flex: 1 1 auto;
}

.sf-btn-group > .sf-btn:not(:first-child),
.sf-btn-group > .sf-btn-group:not(:first-child) {
  margin-left: -1px;
}
.sf-btn-group > .sf-btn:not(:last-child):not(.dropdown-toggle),
.sf-btn-group > .sf-btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}
.sf-btn-group > .sf-btn:not(:first-child),
.sf-btn-group > .sf-btn-group:not(:first-child) > .btn {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}

.sf-state-error {
  border-color: $dangerColor !important;
  color: $dangerColor !important;
}

.sf-transition-standard {
  transition: all 0.15s linear;
}
